<template>
  <div class="reward-list">

    <LoadingBox
      v-if="loading"
      class="loading-box">
    </LoadingBox>

    <LoyaltyRewardListItem
      v-for="item in entities"
      :key="item.slug"
      :entity="item"
      :subscriber="subscriber"
      :campaigns-base-url="campaignsBaseUrl"
    >
    </LoyaltyRewardListItem>
  </div>
</template>

<script>
import LoadingElementMixin from '@paiuolo/pai-vue-mixins/mixins/LoadingElementMixin'
import xhrGetMixin from '@paiuolo/pai-vue-mixins/mixins/xhrGetMixin'
import EntityListMixin from '@paiuolo/pai-vue-mixins/mixins/EntityListMixin'

import LoadingBox from '../../../elements/loader/LoadingBox'

import LoyaltyRewardListItem from './LoyaltyRewardListItem'

export default {
  name: 'LoyaltyRewardList',
  mixins: [
    LoadingElementMixin,
    xhrGetMixin,
    EntityListMixin
  ],
  components: {
    LoadingBox,
    LoyaltyRewardListItem
  },
  props: {
    subscriber: {
      type: Object,
      default: null
    },
    rewards: {
      type: Array,
      default: null
    },
    rewardsUrl: {
      type: String,
      default: 'https://game.q-cumber.com/api/v1/loyalty/rewards/'
    },
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.com/api/v1/auth/login/'
    },
    campaignsBaseUrl: {
      type: String,
      default: '/eco-fedelta/'
    }
  },
  data () {
    return {
      xhrGetUrl: this.rewardsUrl,
      xhrGetParams: {
        withCredentials: true
      }
    }
  },
  mounted () {
    this.$on('performingXhrGet', () => {
      this.startLoading()
    })
    this.$on('xhrGetResponse', (response) => {
      this.entities = response.data.results
      this.stopLoading()
    })

    if (this.rewards) {
      this.entities = this.rewards
    } else {
      this.performXhrGet()
    }
  },
  watch: {
    rewards (n) {
      this.entities = n
    }
  }
}
</script>


<style lang="scss">
@import "../../../../scss/loyalty.theme";

.reward-list {
  position: relative;
  min-height: 170px;
}
</style>