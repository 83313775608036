<template>
  <swiper-js
    class="obtain-loyalty-reward"
    ref="swiper"
    :swipeable="false"
    :allowSlideNext="false"
    :allowSlidePrev="false">

<!-- read_info -->
      <div class="swiper-slide">
        <div class="step">
          <ObtainLoyaltyRewardCard
            :entity="entity"
          >
            <div class="scrollable" v-html="entity.description"></div>
          </ObtainLoyaltyRewardCard>
          <div class="actions">
            <div class="separator"></div>
            <LoyaltyMdcButton
              v-if="canDownloadReward"
              big
              @click="activateStep('check_user')"
              >
              {{nextButtonString}}
            </LoyaltyMdcButton>
            <LoyaltyMdcButton
              v-else
              big
              @click="operationCancelled"
              >
              Ok
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- check_user -->
      <div class="swiper-slide">
        <div class="step">
          <ObtainLoyaltyRewardCard
            :entity="entity"
          >
            <user-card
              ref="userCard"
              :external-user-profile="subscriber"
              :login-url="loginUrl"
              no-over
              >
            </user-card>
          </ObtainLoyaltyRewardCard>
          <div class="actions">
            <LoyaltyMdcButton
              @click="activateStep('read_info')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator"></div>
            <LoyaltyMdcButton
              v-if="subscriber === null"
              big
              @click="performLogin"
              >
              Accedi
            </LoyaltyMdcButton>
            <LoyaltyMdcButton
              v-else
              big
              @click="activateStep(check_availability)"
              >
              Continua
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- check_availability -->
      <div class="swiper-slide">
        <div class="step">
          <ObtainLoyaltyRewardCard
            :entity="entity"
          >
          <h3 v-if="obtainRewardError === null">
            {{getStepDescription('check_availability')}}
          </h3>
          <h3 class="error"
            v-else>
            {{obtainRewardError}}
          </h3>
          </ObtainLoyaltyRewardCard>
          <div class="actions">
            <div class="separator"></div>
            <LoyaltyMdcButton
              big
              @click="operationCancelled"
              >
              OK
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>
<!-- start_download -->
      <div class="swiper-slide">
        <div class="step">
          <ObtainLoyaltyRewardCard
            :entity="entity"
          >
            <div>
              {{getStepDescription('start_download')}}
            </div>
          </ObtainLoyaltyRewardCard>
          <div class="actions">
            <LoyaltyMdcButton
              @click="activateStep('check_availability')"
              >
              &lt;
            </LoyaltyMdcButton>
            <div class="separator"></div>
            <LoyaltyMdcButton
              big
              @click="operationCompleted"
              >
              OK
            </LoyaltyMdcButton>
          </div>
        </div>
      </div>

</swiper-js>
</template>

<script>
import EntityAsPropMixin from '@paiuolo/pai-vue-mixins/mixins/EntityAsPropMixin'
import LoginRequiredMixin from '@paiuolo/pai-vue-mixins/mixins/LoginRequiredMixin'

import SwiperJs from '@paiuolo/pai-vue-swiper/elements/swiper/SwiperJs'
import StepOperationSwiperJsMixin from '@paiuolo/pai-vue-swiper/mixins/StepOperationSwiperJsMixin'

import ObtainLoyaltyRewardCard from './ObtainLoyaltyRewardCard'

import LoyaltyMdcButton from '../../../../elements/button/loyalty/LoyaltyMdcButton'
import UserCard from '../../../../entities/user/UserCard'


export default {
  name: 'ObtainLoyaltyReward',
  mixins: [
    StepOperationSwiperJsMixin,
    LoginRequiredMixin,
    EntityAsPropMixin
  ],
  components: {
    LoyaltyMdcButton,
    ObtainLoyaltyRewardCard,
    UserCard,
    SwiperJs
  },
  props: {
    subscriber: {
      type: Object,
      default: null
    },
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.com/login/'
    }
  },
  data () {
    return {
      nextButtonString: this.gettext('Download reward'),
      obtainRewardError: null,
      stepOperation: {
        key: 'download_reward_CBBO-2018',
        name: 'Download reward CBBO 2018',
        description: this.gettext('Download reward'),
        activeStep: null,
        steps:
          [{
            key: 'read_info',
            name: 'Read informations',
            description: this.gettext('Download Reward'),
            enabled: false,
            next: 'start_download',
            result: null,
            error: null
          },
          {
            key: 'check_user',
            name: 'Check user',
            description: this.gettext('Checking user..'),
            enabled: false,
            next: 'check_availability',
            result: null,
            error: null
          },
          {
            key: 'check_availability',
            name: 'Check availability',
            description: this.gettext('Checking availability..'),
            enabled: false,
            next: 'start_download',
            result: null,
            error: null
          },
          {
            key: 'start_download',
            name: 'Start download',
            description: this.gettext('Downloading..'),
            enabled: false,
            next: null,
            result: null,
            error: null
          }]
      }
    }
  },
  computed: {
    canDownloadReward () {
      return this.entity.obtained
    }
  },
  methods: {
    clear () {
      this.activateStep('read_info')
    },
    stepActivated (step) {
      if (step === 'check_user') {
        if (this.subscriber !== null) {
          setTimeout(() => {
            this.activateStep(3)
          }, 200)
        }
      }
    },
    operationCompleted () {
      // console.log('Operation completed')
      this.operationCompleted = true
      this.$emit('rewardDownloaded', this.entity)
    },
    operationCancelled () {
      // console.log('Operation cancelled')
      this.$emit('operationCancelled')
    },
    obtainReward () {
      let url = '/api/v1/loyalty/rewards/' + this.entity.id + '/obtain'

      this.$http.post(url,
        {},
        {
          withCredentials: true
        }
      ).then(() => { // (response)
        // console.log('obtainReward response', response, response.data)
        this.activateStep(5)
      }).catch((err) => {
        // console.log('obtainReward error', err, err.response, err.response.data)
        if (err.response) {
          if (err.response.data) {
            this.obtainRewardError = err.response.data
            this.$forceUpdate()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../scss/loyalty.theme";

@import "@material/card/mdc-card";

.obtain-loyalty-reward {
  .step {
    background-color: #fff;
    padding-bottom: 32px;

    .description {
      font-size: 32px;
      line-height: 36px;
    }

    .tariIdInput {
      margin-top: 32px;
    }
  }

  .actions {
    @include flexbox();
    @include flex-direction(row);

    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding: 0 36px;

    .separator {
      @include flex(1);
    }

    @media screen and (max-width: $break-phone) {
      padding: 0 24px;
    }
  }
}
</style>
