<template>
  <div class="loyalty-reward-list-item">

    <div class="entity-detail">
      <div class="image" :style="logoStyle"></div>
      <div class="info">
        <div class="item-slogan" v-if="entity.slogan" v-html="entity.slogan"></div>
        <div class="item-slogan" v-else>
          <img v-if="entity.image" :src="entity.image" :alt="entity.name" />
          <h4 v-else>{{entity.name}}</h4>
        </div>
      </div>
    </div>

    <div class="tastiScelta">

      <LoyaltyEntityListButton
        ref="rewardInfoButton"
        @click="showDialog"
        >
        <div>
          <i class="material-icons mdc-button__icon" aria-hidden="true">
            info
          </i>
          <span>Info</span>
        </div>
      </LoyaltyEntityListButton>

      <LoyaltyEntityListButton
        right
        ref="downloadRewardButton"
        @click="downloadRewardClicked"
        >
          <div v-if="canDownloadReward">
            <i class="material-icons mdc-button__icon" aria-hidden="true">
              card_giftcard
            </i>
            <span>{{downloadText}}</span>
            <span>{{rewardText}}</span>
          </div>

          <div v-if="rewardHasBeenAssigned">
            <i class="material-icons mdc-button__icon" aria-hidden="true">
              cake
            </i>
            <span>{{youWonText}}</span>
          </div>

          <big v-if="rewardHasNotBeenAssigned">
            <span v-if="entity.campaign.subscribed">
              {{entity.subscription_score}}<br/>
              {{pointsText}}
            </span>
            <div v-else-if="entity.is_active">
              <i class="material-icons mdc-button__icon" aria-hidden="true">person</i>
              {{subscribeText}}
            </div>
            <div v-else>
             Campagna terminata
            </div>
          </big>

          <div v-if="canShowMissingScore">
            <span>{{missingText}}</span>
            <big>{{missingScore}}</big>
            <span>{{pointsText}}</span>
          </div>

          <div v-if="canShowRequiredScore">
            <big>{{requiredScore}}</big>
            <span>{{pointsText}}</span>
          </div>
      </LoyaltyEntityListButton>
    </div>

    <MdcDialog
      ref="dialog"
      borderless
      @closed="modalClosed"
      @opened="modalOpened" >

      <ObtainLoyaltyReward
        v-if="showOperation"
        ref="obtainReward"
        :entity="entity"
        :subscriber="subscriber"
        @rewardDownloaded="hideDialog"
        @operationCancelled="hideDialog" />

    </MdcDialog>
  </div>
</template>

<script>
import EntityAsPropMixin from '@paiuolo/pai-vue-mixins/mixins/EntityAsPropMixin'
import EntityWithLogoMixin from '@paiuolo/pai-vue-mixins/mixins/EntityWithLogoMixin'

import ObtainLoyaltyReward from './step-operations/ObtainLoyaltyReward'

import MdcDialog from '../../../elements/dialog/MdcDialog'

import LoyaltyEntityListButton from '../../../elements/button/loyalty/LoyaltyEntityListButton'


export default {
  name: 'LoyaltyRewardListItem',
  mixins: [
    EntityAsPropMixin,
    EntityWithLogoMixin
  ],
  components: {
    LoyaltyEntityListButton,
    ObtainLoyaltyReward,
    MdcDialog
  },
  props: {
    subscriber: {
      type: Object,
      default: null
    },
    campaignsBaseUrl: {
      type: String,
      default: '/eco-fedelta/'
    }
  },
  data () {
    return {
      downloadText: this.gettext('Download'),
      rewardText: this.gettext('reward'),
      youWonText: this.gettext('You won!'),
      pointsText: this.gettext('points'),
      missingText: this.gettext('You miss'),
      manuallyAssignedText: this.gettext('Manually assigned'),
      rewardsText: this.gettext('Premi'),
      subscribeText: this.gettext('Subscribe'),
      showOperation: false
    }
  },
  computed: {
    isManuallyAssigned () {
      // console.log(this.entity.name, 'reward has been assigned?', this.entity, this.entity.assigned, this.entity.campaign.subscribed)
      return this.entity && this.entity.is_manually_assigned
    },
    rewardHasBeenAssigned () {
      return this.isManuallyAssigned && this.entity.assigned
    },
    rewardHasNotBeenAssigned () {
      return this.isManuallyAssigned && !this.entity.assigned
    },
    canDownloadReward () {
      return !this.isManuallyAssigned && this.entity.missing_score === 0
    },
    canShowRequiredScore () {
      return !this.isManuallyAssigned && this.entity.campaign && !this.entity.campaign.subscribed
    },
    canShowMissingScore () {
      return this.entity.campaign && !this.canShowRequiredScore && !this.isManuallyAssigned && !this.rewardHasBeenAssigned && this.entity.campaign.subscribed
    },
    requiredScore () {
      if (this.entity) {
        return this.entity.score
      }
      return 0
    },
    missingScore () {
      if (this.entity) {
        return this.entity.missing_score
      }
      return 0
    }
  },
  methods: {
    showDialog (ev) {
      this.$refs.dialog.open(ev)
    },
    hideDialog () {
      this.$refs.dialog.close()
    },
    downloadRewardClicked () {
      document.location.href = '/'
    },
    modalOpened () {
      this.$nextTick(() => {
        this.showOperation = true
      })
    },
    modalClosed () {
      this.showOperation = false
    }
  }
}
</script>

<style lang="scss">
@import "../../../../scss/loyalty.theme";

.loyalty-reward-list-item {
  @include loyalty-entity-list-item;
}

</style>
